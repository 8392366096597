export const breakpoints = ["600px", "960px", "1024px", "1440px"]
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export const container = `
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: ${breakpoints.md}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

export default {
  breakpoints,
  logo: {
    size: [40, 40, 45, 50, 50],
    marginTop: [50],
    marginBottom: [50, 50, 60],
  },
  colors: {
    dark: "#34383a",
    medium: "#666560",
    bright: "#cac6c4",
    blue: "#528cb4",
  },
  typeStyles: {
    heading: {
      fontSize: [27, 27, 30, 40, 45],
      fontFamily: `"Lato", sans-serif`,
      lineHeight: 1.4,
    },
    subhead: {
      fontSize: [25, 25, 30, 40, 45],
      fontFamily: `"Lato", sans-serif`,
      lineHeight: 1.1,
      fontWeight: 700,
      marginBottom: "60px",
    },
    body: {
      fontSize: [18, 18, 18, 20],
      fontFamily: `"Lato", sans-serif`,
      lineHeight: 1.5,
      letterSpacing: "0.43px",
      fontWeight: 400,
    },
  },
}
