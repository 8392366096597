import { string } from "prop-types"
import styled from "styled-components"

import {
  color,
  colorStyle,
  compose,
  layout,
  space,
  textStyle,
  typography,
  variant,
} from "styled-system"

const Text = styled("p")(
  textStyle,
  colorStyle,
  compose(color, layout, space, typography),
  variant({
    prop: "typeStyle",
    scale: "typeStyles",
    variants: {
      default: {},
    },
  })
)

Text.propTypes = {
  typeStyle: string,
}

Text.defaultProps = {
  typeStyle: undefined,
}

export default Text
