import React from "react"
import { node } from "prop-types"
import styled, { ThemeProvider } from "styled-components"

import GlobalStyles from "../theme/GlobalStyles"
import theme from "../theme/config"

const Container = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Container>
      <GlobalStyles />
      {children}
    </Container>
  </ThemeProvider>
)

Layout.propTypes = {
  children: node.isRequired,
}

export default Layout
