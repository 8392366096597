/* global window */
import React from "react"
import styled from "styled-components"
import { container } from "../theme/config"
import IconGithub from "../icons/Github"
import IconLinkedin from "../icons/Linkedin"
import IconEmail from "../icons/Email"

const ContactWrapper = styled.div`
  ${container}
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 100px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  a,
  button {
    display: flex;
    position: relative;
    outline: none;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 2px solid #34383a;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: border-color 0.2s ease-out;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      background-color: transparent;
      transform: translate(-50%, -50%) scale(0.5);
      transition: background-color 0.15s ease-out, transform 0.2s ease-out;
    }
  }

  a {
    margin-left: 20px;
  }

  svg {
    position: relative;
    max-width: 23px;
    transition: transform 0.2s ease-out;
    #shape {
      fill: #34383a;
    }
  }

  #email {
    width: 22px;
  }

  #linkedin {
    width: 20px;
    margin-top: -1px;
    margin-left: 0.5px;
  }

  #github {
    width: 24px;
  }

  a:hover,
  button:hover {
    border-color: #528cb4;
    &:before {
      background-color: #528cb4;
      transform: translate(-50%, -50%) scale(1);
    }
    svg {
      transform: scale(1.15);
    }
    #shape {
      fill: white;
    }
  }
`

const email = () => {
  const name = "jens"
  const domain = "weareprofound"
  window.location.href = `mailto:${name}@${domain}.com`
}

export default () => (
  <ContactWrapper>
    <button type="button" onClick={email}>
      <IconEmail />
    </button>
    <a href="https://www.linkedin.com/in/jensfischer-nyc/">
      <IconLinkedin />
    </a>
    <a href="https://www.github.com/artofrawr">
      <IconGithub />
    </a>
  </ContactWrapper>
)
