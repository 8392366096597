import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #ffffff;
  }

  h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-family: "Lato", sans-serif;
    font-size: 40px;
    font-weight: 700;
    color: #34383a;
  }

  main {
    overflow: hidden;w
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-7px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes rotateIn {
    0% {
      opacity: 0;
      transform: scale(0) rotate(0deg);
    }
    100% {
      opacity: 1;
      transform: scale(1) rotate(180deg);
    }
  }

`

export default GlobalStyles
